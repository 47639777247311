import React from "react"
import "semantic-ui-css/semantic.min.css"
import { Container, Divider, Image } from "semantic-ui-react"
import SEO from "../components/seo"
import latticeLogo from "../images/lattice-logo-2019-newText-darkBackground.png"

const summary = "Combinatorial design tool for MoClo assembly."
const moclo_description = [
  "The Modular Cloning (MoClo) method is a derivative of Golden Gate assembly technology.",
  "DNA part inserts are engineered with flanking Type IIS restriction enzymes, BsaI and BpiI, to assemble in a predictable manner.",
].join(" ")
const project_about = [
  "In 2016/17 Lattice engineers implemented a web-based software tool to build combinatorial plasmid libraries generated from a set of prepared MoClo-compatible DNA parts.",
  "With this tool users can: upload/parse part files, organize part sets in buckets, generate all part combinations that make a feasible assembly, inspect/export a plate view, and more",
].join(" ")
const project_about_deprecation = [
  "The server running the MoClo Assembly Tool has been shut down. If you are interested in learning more about the programmatic approach or if you would like to work with our team on another project, please reach out to us at contact@latticeautomation.com.",
]
const lattice_about = [
  "Lattice makes software solutions to fit your synthetic biology workflows and pipelines.",
  "We use experimental data, institutional knowledge, and best practices to enable bio-design productivity and learning.",
].join(" ")

const IndexPage = () => (
  <Container textAlign="center">
    <SEO
      title="MoClo Assembly tool - Lattice Automation"
      keywords={[`gatsby`, `application`, `react`]}
    />
    <h1>MoClo Assembly Tool</h1>
    <h5 className="overview">{summary}</h5>
    <Container text textAlign="left" className="description-container">
      <p className="moclo-description">
        {moclo_description}
        <span>
          <a
            className="link-out"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.addgene.org/cloning/moclo/?gclid=EAIaIQobChMItfaD2eqM4gIVyIqzCh1GcQGeEAAYASAAEgIfKfD_BwE"
          >
            {" "}
            More about MoClo assembly.
          </a>
        </span>
      </p>
    </Container>
    <Container text textAlign="left" className="description-container">
      <p className="project-about">
        {project_about}
        <span>
          <a
            className="link-out"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.jove.com/video/54703/automated-robotic-liquid-handling-assembly-of-modular-dna-devices"
          >
            <sup>1</sup>
          </a>
          {"."}
          {project_about_deprecation}
        </span>
      </p>
    </Container>
    <Container text textAlign="left" className="description-container">
      <p className="lattice-about">
        {lattice_about}
        <span>
          <a
            className="link-out"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.latticeautomation.com/"
          >
            {" "}
            Learn more about Lattice.
          </a>
        </span>
      </p>
    </Container>
    <Container text textAlign="left" className="attribution">
      <p>
        <span>Developers:</span> Joshua Timmons, Lloyd McCarthy
      </p>
      <p>
        <span>Researchers:</span> Luis Ortiz, Marilene Pavin, Douglas Densmore
        PhD.
      </p>
      <p className="citation">
        <span>{"[1] "}</span>
        <a
          className="link-out"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.jove.com/video/54703/automated-robotic-liquid-handling-assembly-of-modular-dna-devices"
        >
          Ortiz L, et. al. Automated Robotic Liquid Handling Assembly of Modular
          DNA Devices. J Vis Exp. 2017;(130)
        </a>
      </p>
    </Container>
    <Divider />
    <Image src={latticeLogo} size="small" centered />
  </Container>
)

export default IndexPage
